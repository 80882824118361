import { template as template_c55df646c1c54335af6b4d86fd5d43fa } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_c55df646c1c54335af6b4d86fd5d43fa(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
