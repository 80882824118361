import { template as template_e01c3aebb41842aaa7c8e5a17d420748 } from "@ember/template-compiler";
const FKText = template_e01c3aebb41842aaa7c8e5a17d420748(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
